'use client';

import { AuthLayoutWrapper } from '@/components/auth/authLayout';
import '@/styles/globals.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { logout } from '@/redux/slices/authSlice';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const dispatch = useDispatch();

  const router = useRouter();

  useEffect(() => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');

    if (!accessToken) {
      dispatch(logout());
      return;
    }

    if (isAuthenticated) {
      // If authenticated, redirect to dashboard or home
      router.replace('/');
    }
  }, [isAuthenticated, router]);

  if (isAuthenticated) {
    return null; // or you could return a loading spinner or something else
  }

  return <AuthLayoutWrapper>{children}</AuthLayoutWrapper>;
}
