import Image from 'next/image';
import cover from '../../../public/assets/images/zynoff_hero_image.svg';

interface Props {
  children: React.ReactNode;
}

export const AuthLayoutWrapper = ({ children }: Props) => {
  return (
    <div className="flex w-full h-screen items-center justify-center bg-light-primary-100">
      <div className="flex w-[80%] 2xl:max-w-[1418px] h-auto 2xl:h-[840px] shadow-lg rounded-lg overflow-hidden">
        {/* Left Side: Form */}
        <div className="flex-[2] xl:flex-[5] 2xl:flex-[1] flex flex-col items-center py-5 lg:py-8 justify-center bg-white">
          <div className="px-5 lg:px-20">{children}</div>
        </div>

        {/* Right Side: Image Background */}
        <div className="hidden xl:flex xl:flex-[5] 2xl:flex-[1] relative  bg-[#001E00]">
          <div className="relative w-full h-full">
            <Image
              className="object-contain"
              src={cover}
              alt="background image"
              fill
              priority
              sizes="(max-width: 768px) 100vw, 50vw"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
